import axios from '@/jslib/axios'

export const getClientList = params => {
  return axios({
    method: 'get',
    url: '/admin/client/list',
    params
  })
}
export const getClientPolicyList = params => {
  return axios({
    method: 'get',
    url: '/admin/client/policy',
    params
  })
}
export const createClient = data => {
  return axios({
    method: 'post',
    url: '/admin/client/create',
    data
  })
}
export const updateClient = data => {
  return axios({
    method: 'post',
    url: '/admin/client/update',
    data
  })
}
export const getClientDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/client/detail',
    params
  })
}
export const getClientClaimSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/claim/client/list/summary',
    params
  })
}
export const getClientClaimList = params => {
  return axios({
    method: 'get',
    url: '/admin/claim/client/list',
    params
  })
}
export const checkBlacklist = params => {
  return axios({
    method: 'get',
    url: '/admin/client/checkBlacklist',
    params
  })
}
