<template>
  <section>
    <div
      v-show="!showUpload"
      class="file-box theme-second-blue">
      <div>
        <div
          class="flex-none flex-sb">
          <div class="upload-content">
            <v-upload
              :card="true"
              :fileData="fileData"
              is-show-detail
              :otherData="otherData"
              :uploadData="uploadData"
              :direct="direct"
              :isLimit="false"
              :disabled="true"
              @uploadSuccess="handlerUploadSuccess"
              @view="handlerView"
            />
            <div
              class="btn-box btn-last"
            >
              <template v-if="edit">
                <span
                  v-if="canDel"
                  @click="handlerDelete">
                  <font-awesome-icon
                    class="icon"
                    icon="trash-alt"/>
                </span>
                <span
                  @click="handlerView">
                  <font-awesome-icon
                    class="icon"
                    icon="search"
                  />
                </span>
              </template>
            </div>
          </div>
        </div>
        <el-image-viewer
          v-if="showViewer"
          :on-close="()=>{showViewer=false}"
          :url-list="imgList" />
      </div>
    </div>
    <v-upload
      v-show="showUpload"
      ref="upload"
      class="mg-b-10"
      label="Upload"
      is-show-detail
      :otherData="otherData"
      :uploadData="uploadData"
      has-loading
      :direct="direct"
      card
      :isLimit="false"
      @uploadSuccess="handlerUploadSuccess" />
    <v-dialog
      v-model="isOpen"
      showClose
      fullscreen>
      <embed
        v-if="isPdf"
        :src="fileUrl"
        width="100%"
        height="100%"
        type="application/pdf">
    </v-dialog>
  </section>

</template>
<script>
// import { deleteOrderFile, deleteOrderDriverFile } from '@/api/order'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'FileContainer',
  components:{
    ElImageViewer
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    canDel: {
      type: Boolean,
      default: true
    },
    fileData: {
      type: Object,
      default: () => ({})
    },
    otherData: {
      type: Object,
      default: () => ({})
    },
    direct: {
      type: Number,
      default: 1 // 只上傳不入庫
    },
    uploadData: {
      type: Object,
      default: () => ({})
    },
    limit:{
      type: Number,
      default: 1 // 只上傳不入庫
    },
    multiple:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    isDialog:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showViewer:false,
      imgList:[],
      isOpen:false,
    }
  },
  computed: {
    defaultName() {
      if(!this.fileData || !this.fileData.url) {
        return 'No File'
      }
      return (this.fileData  && this.fileData.client_name)? this.fileData.client_name: 'document.pdf'
    },
    showUpload() {
      if(this.edit && !this.fileData.url) {
        return true
      }
      return false
    },
    fileUrl(){
      let {url} = this.fileData||{}
      return url||''
    },
    isPdf(){
      let {client_name,path} = this.fileData
      if(String(client_name).toLowerCase().indexOf('.pdf') != -1||String(path).toLowerCase().indexOf('.pdf') != -1) {
        return true
      }
      return false
    }
  },
  methods: {
    handlerView(){
      // if(this.isPdf)
      // {
      //   if(this.isDialog){
      //     this.$emit('view')
      //   }else
      //     this.isOpen = true
      //   // window.open(this.fileUrl)
      // }
      // else
      // {
      //   this.imgList=[this.fileUrl]
      //   this.showViewer = true
      // }
      // window.open(this.fileUrl, '_blank')


      const windowWidth = 720 // 新窗口的宽度
      const screenWidth = window.screen.width // 屏幕的宽度
      const windowLeft = screenWidth - windowWidth // 窗口的左侧位置

      const newWindow = window.open(this.fileUrl,'','width=720,height=720,top=200'+',left=' + (window.outerWidth - 100))
      newWindow.addEventListener('DOMContentLoaded', function() {
        const styleTag = newWindow.document.createElement('style')
        styleTag.innerHTML =
          `
          body {
              display: flex;
              justify-content: flex-end;
            }
           `

        newWindow.document.head.appendChild(styleTag)
      })
    },
    handlerDelete() {
      // // 區分是否直接删除数据库
      // if(this.direct === 0) {
      //   // 判斷是否為記名司機
      //   const { type } = this.uploadData
      //   if(type === 'nm_drvr_licence_pic' || type === 'nm_drvr_id_type_pic') {
      //     this.network().deleteOrderDriverFile()
      //   }
      //   else {
      //     this.network().deleteOrderFile()
      //   }
      // }
      // else {
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.$emit('delete', this.otherData)
      }).catch(()=>{
      })

      // this.$refs.upload.handleRemove()
      // }
    },
    downloadFile() {
      // if(this.fileData && this.fileData.url) {
      //   window.open(this.fileData.url, '_blank')
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: 'no file'
      //   })
      // }
    },
    handlerUploadSuccess(uploadRsult) {
      this.$emit('uploadSuccess', uploadRsult)
    },
    network() {
      return {
        deleteOrderFile: async () => {
          const { data } = await deleteOrderFile(this.uploadData)
          this.$emit('delete', this.otherData)
        },
        deleteOrderDriverFile: async () => {
          const { data } = await deleteOrderDriverFile({
            type: this.uploadData.type,
            order_id: this.uploadData.order_id,
            driver_num: this.uploadData.num
          })
          this.$emit('delete', this.otherData)
        }
      }
    },
    openFile(url){
      if(url)
        window.open(url,'_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.file-box {
  position: relative;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  //margin-bottom: 15px;
  //padding: 5px 15px;
  //min-height: 65px;
  //border: 1px solid #dddfe7;
  //border-radius: 4px;
  //background: #fcfcfc;
  .file-info {
    //flex: 1;
    //max-width: 100px;
    // width: calc(100% - 100px);
    padding: 0 10px;
    color: #616266;
    span{
      font-weight: normal!important;
    }
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  //.btn-box {
  //  //display: flex;
  //  justify-content: flex-end;
  //  flex-wrap: wrap;
  //  align-items: center;
  //
  //  .el-button {
  //    margin-left: 0;
  //    margin-right: 10px;
  //  }
  //}
  .btn-max-width {
    min-width: 270px;
  }
  .btn-min-width {
    min-width: 100px;
  }
}
.hand{
  cursor:pointer;
}
.upload-content{
  position: relative;
  .btn-box{
    position: absolute;
    top: 6px;
    right: 6px;
    span{
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
      cursor:pointer;
      .icon{
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }
}
.el-image-viewer__close{
  font-size: 46px!important;
}
/deep/.el-dialog__body{
  overflow-y: hidden;
  height: 100vh;

}
/deep/.el-dialog{
  &.is-fullscreen{
    overflow-y: hidden;
    z-index: 99999;
  }
  .el-dialog__close {
    color: $primary;
    font-size: 48px;
    border: 3px solid $primary;
    border-radius: 50%;
    margin-top: 20px;
    margin-right: 20px;
    z-index: 99999;
  }
}
</style>
