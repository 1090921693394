export default [
  {
    path: '/create/claim',
    name: 'createClaim',
    component: () => import('@/views/claim/createClaim'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/edit/claim/:id',
    name: 'editClaim',
    component: () => import('@/views/claim/createClaim'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/member/clients/detail/:id',
    name: 'claimDetail',
    component: () => import('@/views/claim/detail'),
    meta: {
      keepAlive: false,
    }
  },
]
