
import { getGlobalOptions } from '@/api/common'
import { checkBlacklist } from '@/api/clients'
const SET_GLOBAL_OPTIONS = 'SET_GLOBAL_OPTIONS'
const SET_BACK_PATH = 'SET_BACK_PATH'
const SET_ACCOUNTER_NAME = 'SET_ACCOUNTER_NAME'
const SET_THEME_COLOR = 'SET_THEME_COLOR'
const SET_CLIENT_BLACK_STATUS = 'SET_CLIENT_BLACK_STATUS'
const SET_TICKET_NOTIFY = 'SET_TICKET_NOTIFY'
const SET_LIST_FILTER_PARAMS = 'SET_LIST_FILTER_PARAMS'
const SET_USER_NAME = 'SET_USER_NAME'

const state = {
  globalOptions: null,
  isBackPath:false,
  accounter:null,
  username:null,
  themeColor:'blue',
  ticketNotify:false,
  clientBlackData:{
    blacklist:false,
    claimed:false,
  },
  listFilterParams:{}
}

const mutations = {
  [SET_LIST_FILTER_PARAMS]: (state, value) => {
    state.listFilterParams = value
  },
  [SET_TICKET_NOTIFY]: (state, value) => {
    state.ticketNotify = value
  },
  [SET_CLIENT_BLACK_STATUS]: (state, value) => {
    state.clientBlackData = value
  },
  [SET_GLOBAL_OPTIONS]: (state, value) => {
    state.globalOptions = value
  },
  [SET_BACK_PATH]: (state, value) => {
    state.isBackPath = value
  },
  [SET_ACCOUNTER_NAME]: (state, value) => {
    state.accounter = value
  },
  [SET_USER_NAME]: (state, value) => {
    state.username = value
  },
  [SET_THEME_COLOR]: (state, value) => {
    state.themeColor = value
  },
}

const actions = {
  setListFilter({ commit }, val) {
    commit(SET_LIST_FILTER_PARAMS, val)
  },
  setTicketNotify({ commit }, val) {
    commit(SET_TICKET_NOTIFY, val)
  },
  setThemeColor({ commit }, val) {
    commit(SET_THEME_COLOR, val)
  },
  setAccounterName({ commit }, val) {
    commit(SET_ACCOUNTER_NAME, val)
  },
  setUserName({ commit }, val) {
    commit(SET_USER_NAME, val)
  },
  setGlobalOptions({ commit }, val) {
    commit(SET_GLOBAL_OPTIONS, val)
  },
  setBlacklistStatus({ commit }, val) {
    commit(SET_CLIENT_BLACK_STATUS, val)
  },
  setIsBackPath({ commit }, val) {
    commit(SET_BACK_PATH, val)
  },
  // 檢查是否有遺漏的 options 數據未獲取成功，重新請求一次
  checkOptionsData({ state, dispatch  }) {
    // 全局數據
    if(!state.globalOptions) {
      dispatch('updateGlobalOptions')
    }
  },
  updateGlobalOptions({ commit },params) {
    return new Promise((resolve, reject) => {
      getGlobalOptions({
        hideLoading: true,
        ...params
      }).then(res => {
        let { data } = res
        // 處理數據
        // const resultData = handlerOptionsData(data)
        commit(SET_GLOBAL_OPTIONS, data)
        sessionStorage.setItem('GLOBAL_OPTIONS', JSON.stringify(data))
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  checkBlacklistStatus({ commit },params) {
    return new Promise((resolve, reject) => {
      checkBlacklist({
        hideLoading: true,
        ...params
      }).then(res => {
        let { data } = res
        // 處理數據
        commit(SET_CLIENT_BLACK_STATUS, data)
        // sessionStorage.setItem('GLOBAL_OPTIONS', JSON.stringify(data))
        resolve(res)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
}

// function handlerOptionsData(data) {
//   let result = {}
//   Object.keys(data).some(key => {
//     if(key == 'currency') {
//       let tempArr = []
//       for(let i = 0; i < data[key].length; i++) {
//         tempArr.push({
//           id: data[key][i],
//           name: data[key][i]
//         })
//       }
//       result[key] = tempArr
//     }
//     else {
//       result[key] = data[key]
//     }
//   })
//   return result
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
