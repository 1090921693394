import axios from '@/jslib/axios'


export const uploadOrderFile = (data,hideLoad=false) => {
  return axios({
    method: 'post',
    url: '/admin/order/file/upload',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    hideLoad
  })
}
export const getOrderDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/order/detail',
    params
  })
}
export const computedQuotation = data=>{
  return axios({
    method: 'post',
    url: '/admin/order/quotation',
    data
  })
}
export const setOrderPayment = data=>{
  return axios({
    method: 'post',
    url: '/admin/order/payment',
    data
  })
}
export const setOrderConfirm = data=>{
  return axios({
    method: 'post',
    url: '/admin/order/confirm',
    data
  })
}
export const approvePayment = data=>{
  return axios({
    method: 'post',
    url: '/admin/order/payment/approve',
    data
  })
}
export const deleteOrder = params => {
  return axios({
    method: 'delete',
    url: '/admin/order',
    params
  })
}
export const clientSettle = data => {
  return axios({
    method: 'post',
    url: '/admin/client/settle',
    data
  })
}
export const setDocumentStatus = data => {
  return axios({
    method: 'post',
    url: '/admin/order/document/status',
    data
  })
}
export const updateATM = data => {
  return axios({
    method: 'post',
    url: '/admin/order/update/atm',
    data
  })
}
export const orderDownQuotation = params => {
  return axios({
    method: 'get',
    url: '/admin/order/quotation/pdf',
    params
  })
}
export const updateDoc = data => {
  return axios({
    method: 'post',
    url: '/admin/order/info',
    data
  })
}
export const generateOrder = data => {
  return axios({
    method: 'post',
    url: '/admin/order/prop/generate',
    data
  })
}
export const changePolicyType = data => {
  return axios({
    method: 'post',
    url: '/admin/order/policy/type',
    data
  })
}
export const getInternalMarkList = params => {
  return axios({
    method: 'get',
    url: '/admin/order/internal/remark',
    params
  })
}
export const addInternalMark = data => {
  return axios({
    method: 'post',
    url: '/admin/order/internal/remark',
    data
  })
}
export const updateSupplementary = data => {
  return axios({
    method: 'post',
    url: '/admin/order/supplementary',
    data
  })
}
