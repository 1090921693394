<template>
  <div class="v-radio-group">
    <el-radio-group
      v-model="radio"
      :size="size"
      :disabled="disabled"
      @change="changeRadio">
      <template v-if="!button">
        <el-radio
          v-for="(item, index) in list"
          :key="index"
          :border="border"
          :disabled="disabledList.includes(item[valueKey])"
          :class="{ 'block': block }"
          :label="item[valueKey]">{{ item[labelKey] }}</el-radio>
      </template>
      <template v-else>
        <el-radio-button
          v-for="(item, index) in list"
          :key="index"
          :disabled="disabledList.includes(item[valueKey])"
          :class="{ 'block': block,'theme':button }"
          :label="item[valueKey]">{{ item[labelKey] }}</el-radio-button>
      </template>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'VRadioGroup',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledList: {
      type: Array,
      default: () => ([])
    },
    button: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radio: ''
    }
  },
  computed: {
    isNumber() {
      const id = this.list[0][this.valueKey]
      return typeof id === 'number'? true: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if(val === null || val === '') {
          this.radio = ''
        }
        else if(val !== this.radio) {
          this.radio = this.isNumber? Number(val): String(val)
        }
      }
    },
    radio: {
      handler(val) {
        if(val !== this.value) {
          this.$emit('input', val)
          this.$emit('change', val)
        }
      }
    }
  },
  methods: {
    // 這樣寫不知道爲什麽必須改變兩次才會觸發 element 的表單校驗
    changeRadio(val) {
      // this.$emit('input', val)
      // this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-radio-group {
  .block {
    padding-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  ::v-deep .theme{
    background: #fff;
    color: $primary;
    border-radius: 4px;
    .el-radio-button__inner{
      background: #fff;
      color: $primary;
      border-right:1px solid #CBD5E0
    }
  }
  ::v-deep .is-bordered{
      padding: 8px 12px!important;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-align: center;
    min-width: 70px;
    justify-content: center;
    height: 40px!important;
    .el-radio__label{
      padding-left: 0;
    }
  }

  ::v-deep .is-active{
    .el-radio-button__inner{
      background: $primary!important;
      color: #fff;
      border-right: 0.5px solid rgba(255, 255, 255, 0.2);;
    }
  }
  ::v-deep .el-radio-group{
    .el-radio__input.is-checked .el-radio__inner{
      background:  $primary!important;
      border: 1px solid $primary!important;
    }
    .el-radio__input.is-checked+.el-radio__label{
      color: $primary;
    }
    .el-radio__input.is-disabled {
      &.is-checked .el-radio__inner{
        background:  $disable!important;
        border: 1px solid $disable!important;
      }
      &.is-checked+.el-radio__label{
          color: $disable;
        }
    }
  }
}
</style>
