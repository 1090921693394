import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import common from './common'
import headerClassChange from './headerClassChange'
import quotation from './quotation'
import policy from './policy'
import statement from './statement'
import coverage from './coverage'
import travel from './travel'
import claim from './claim'
export default new Vuex.Store({
  modules: {
    common,
    quotation,
    headerClassChange,
    policy,
    statement,
    coverage,
    travel,
    claim
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
