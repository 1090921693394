export default [
  {
    path: '/ticket/list',
    name: 'ticketList',
    component: () => import('@/views/ticket/list'),
    meta: {
      keepAlive: false,
      title:'Ticket'
    }
  },
  {
    path: '/ticket/info',
    name: 'ticketInfo',
    component: () => import('@/views/ticket/info'),
    meta: {
      keepAlive: false,
    }
  }
]
