export default [
  {
    path: '/car/make/list',
    name: 'makeList',
    component: () => import('@/views/setting/list'),
    meta: {
      keepAlive: false,
    }
  },
]
