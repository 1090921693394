import Vue from 'vue'
import {
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Button,
  Radio,
  RadioButton,
  RadioGroup,
  Input,
  Form,
  Dialog,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Pagination,
  Select,
  Option,
  FormItem,
  InputNumber,
  Message,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  Switch,
  Upload,
  Autocomplete,
  Popover,
  Image,
  Loading,
  OptionGroup,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Scrollbar,
  Collapse,
  CollapseItem
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';

[
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Button,
  Radio,
  RadioButton,
  RadioGroup,
  Input,
  Form,
  Dialog,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Pagination,
  Select,
  Option,
  FormItem,
  InputNumber,
  // Message,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  Switch,
  Upload,
  Autocomplete,
  Popover,
  Image,
  // Loading,
  OptionGroup,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Scrollbar,
  Collapse,
  CollapseItem
].some(control => {
  Vue.use(control)
})
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$message = Message
