const SET_CLAIM_DETAIL = 'SET_CLAIM_DETAIL'
const SET_CLAIM_TAB = 'SET_CLAIM_TAB'

const state = {
  claimDetail:null,
  claim_tab:null
}


const mutations = {
  [SET_CLAIM_DETAIL]: (state, value) => {
    state.claimDetail= value
  },
  [SET_CLAIM_TAB]: (state, value) => {
    state.claim_tab= value
  },
}


const actions = {
  setClaimDetail({ commit },val){
    commit(SET_CLAIM_DETAIL, val)
  },
  setClaimTab({ commit },val){
    commit(SET_CLAIM_TAB, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
