export default [
  {
    path: '/account',
    name: 'dashboard',
    component: () => import('@/views/account/dashboard'),
    meta: {
      keepAlive: false,
      title:'Accounting Dashboard'
    }
  },
  {
    path: '/account/transaction',
    name: 'transaction',
    component: () => import('@/views/account/transaction'),
    meta: {
      keepAlive: false,
      // title:'Transaction'
    }
  },
  {
    path: '/account/referral',
    name: 'referral',
    component: () => import('@/views/account/referral'),
    meta: {
      keepAlive: false,
      title:'Accounting Referral'
    }
  },
]