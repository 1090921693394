export default [
  {
    path: '/renewal/pending',
    name: 'pending',
    component: () => import('@/views/renewal/pending'),
    meta: {
      keepAlive: false,
      // title:'Upcoming Renew'
    }
  },
  {
    path: '/renewal/renew',
    name: 'renew',
    component: () => import('@/views/renewal/renew'),
    meta: {
      keepAlive: false,
      // title:'Pending Renew Quote'
    }
  },
  {
    path: '/renewal/paid',
    name: 'paid',
    component: () => import('@/views/renewal/paid'),
    meta: {
      keepAlive: false,
      title:'Paid & Issued'
    }
  },
  {
    path: '/renewal/expired',
    name: 'expired',
    component: () => import('@/views/renewal/expired'),
    meta: {
      keepAlive: false,
      title:'Expired'
    }
  },
]