import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import example from './example'

import account from './account'
import policy from './policy'
import quotation from './quotation'
import member from './member'
import renewal from './renewal'
import ticket from './ticket'
import claim from './claim'
import setting from './setting'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/home'),
      redirect: '/quotation/createQuoteHome',
      children: [
        ...quotation,
        ...account,
        ...policy,
        ...member,
        ...renewal,
        ...ticket,
        ...claim,
        ...setting
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/login')
    },
  ]
})

router.beforeEach((to, from, next) => {
  const { path, fullPath } = to
  const token = localStorage.getItem('token')
  if(path !== '/login' && !token) {
    next({ path: `/login?fullPath=${fullPath}` })
  } else {
    next()
  }
})

router.afterEach(() => {
  let scrollContent = document.querySelector('.scroll-content')
  if (scrollContent) {
    scrollContent.scrollTop = 0
  }
})

export default router
